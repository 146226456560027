/* You can add global styles to this file, and also import other style files */

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import './styles/flexbox.scss';
@import './styles/utility.scss';
@import './styles/customized-theme-ngprime.scss';

:root {
  --primary-color: #2777bd;
  --required: red;
  --primary-lightest: #2777bd;
  --cold-gray-dark: #0d192d;
  --white: #ffffff;
  --danger: #a0090c;
}

@font-face {
  font-family: 'manrope-google-font';
  src: url('/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
  /* Optionally, specify font-weight and font-style */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'manrope-google-font', 'Arial Narrow', Arial, sans-serif;
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
}

.primary-color {
  color: var(--primary-color);
}

input {
  font-size: 14px;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  padding: 6%;

  .image-container {
    background-image: url('/assets/images/background.svg');
    background-repeat: no-repeat;
    width: 220px;
  }
}

.empty-state-title {
  color: #0283c8;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.empty-state-description {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active-chip {
  font-size: 14px;
  border-radius: 8px;
  padding: 2px 8px 2px 8px;
  width: fit-content;
  background-color: #409e6438;
  color: #409e64;
  line-height: 12px;
  text-align: center;
}

.inactive-chip {
  @extend .active-chip;
  background-color: #0d192d1a;
  color: #0e346bb2;
}

.under-construction-chip {
  @extend .active-chip;
  background-color: #f6b81933;
  color: #e97037;
}
