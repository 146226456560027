/* flexbox.scss */

.d-flex {
  display: flex;
}

.d-i-flex {
  display: inline-flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline {
  align-items: baseline;
}

.self-align-bottom {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

@for $i from 0 through 50 {
  .g-#{$i} {
    gap: #{$i}px;
  }
}
