.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.error-message {
  color: var(--required);
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  padding-top: 2px;
}

.color-black {
  color: black;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.position-relative {
  position: relative;
}

@for $i from 0 through 50 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 0 through 100 {
  .pl-#{$i} {
    padding-left: #{$i}px;
  }

  .pr-#{$i} {
    padding-right: #{$i}px;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }

  .pt-#{$i} {
    padding-top: #{$i}px;
  }

  .p-#{$i} {
    padding: #{$i}px;
  }
}

@for $i from 0 through 100 {
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }
}

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.custom-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.footer-buttons {
  margin-top: 20px;
  padding-bottom: 20px;
  margin-right: 20px;
}
